import { DialogDescription } from '@radix-ui/react-dialog'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'

type Props = {
  onClose: () => void
}

export function SweaterSizeGuideDialog({ onClose }: Props) {
  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Størrelsesguide</DialogTitle>
          <DialogDescription> </DialogDescription>
        </DialogHeader>
        <table className="text-left text-sm">
          <thead>
            <tr>
              <th>Ziphood</th>
              <th>XXS</th>
              <th>XS</th>
              <th>S</th>
              <th>M</th>
              <th>L</th>
              <th>XL</th>
              <th>XXL</th>
            </tr>
          </thead>
          <tbody>
            <tr className="border-t">
              <td>Brystomkrets</td>
              <td>76</td>
              <td>86</td>
              <td>96</td>
              <td>106</td>
              <td>116</td>
              <td>126</td>
              <td>136</td>
            </tr>
            <tr className="border-t">
              <td>Lengde fra nakke</td>
              <td>63</td>
              <td>65</td>
              <td>65</td>
              <td>68</td>
              <td>72</td>
              <td>75</td>
              <td>78</td>
            </tr>
            <tr className="border-t">
              <td>Topp skulder til håndledd</td>
              <td>69</td>
              <td>71</td>
              <td>71</td>
              <td>77</td>
              <td>81</td>
              <td>83</td>
              <td>85</td>
            </tr>
          </tbody>
        </table>
        <DialogFooter>
          <Button type="button" variant="secondary" onClick={onClose}>
            Lukk
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
